<template>
  <div class="container-custom" :style="styleObject">
    <div class="content-custom" v-if="isLoadDone">
      <div class="col-12">
        <div class="p-header px-0 py-1">
          <div class="row">
            <div class="col-4"></div>
            <div class="col-4">
              <div class="d-flex justify-content-center mt-1">
                <img
                  v-if="merchantLogo"
                  :src="merchantLogo"
                  class="avatar-header avatar-md border-radius-lg"
                  alt="user1"
                />
                <img
                  v-else
                  src="../assets/img/logo.png"
                  class="avatar-header avatar-md border-radius-lg"
                  alt="user1"
                />
              </div>
            </div>
            <div class="col-4 pe-0">
              <div class="d-flex justify-content-end me-4" v-if="isLogined">
                <img
                  src="../assets/img/qrcode.png"
                  class="avatar-qr avatar-md border-radius-lg"
                  alt="user1"
                  data-bs-toggle="modal"
                  data-bs-target="#qrcode-modal-member"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card-body p-0" v-if="isLogined">
          <div
            class="color-common pb-2 font-weight-bolder px-2 pt-2"
            style="color: var(--color)"
          >
            Welcome,
            {{
              this.currentMember.name || this.currentMember.last_name
                ? (this.currentMember.name ? this.currentMember.name : "") +
                  " " +
                  (this.currentMember.last_name
                    ? this.currentMember.last_name
                    : "")
                : this.currentMember.phone_number
                ? this.currentMember.phone_number
                : ""
            }}!
          </div>
          <div v-if="merchantMember.allow_setup_membership_tiering != 0">
            <div class="row px-3">
              <div class="d-flex justify-content-between">
                <div
                  class="d-flex align-items-center mb-1"
                  @click="gotoTransaction"
                >
                  <h2 class="mb-0">{{ merchantMember.amount_format }}</h2>
                  <img
                    src="../assets/img/coins.png"
                    class="avatar avatar-sm border-radius-lg ps-1"
                    alt="user1"
                  />
                </div>
                <div
                  v-if="merchantMember.reward_type == rewardPoint"
                  class="d-flex align-items-center mb-1"
                  @click="gotoTransaction"
                >
                  <img
                    :src="pointIcon"
                    class="avatar avatar-sm border-radius-lg ps-1"
                    alt="user1"
                    style="height: 30px !important"
                  />
                  <h2 class="mb-0 ps-2">{{ merchantMember.point_format }}</h2>
                </div>
              </div>
              <div
                class="row text-size-12 ps-3"
                v-if="merchantMember.notice_transaction_point_expiry.length > 0"
              >
                <div
                  v-for="(
                    text, index
                  ) in merchantMember.notice_transaction_point_expiry"
                  :key="index"
                >
                  <em v-html="text"></em>
                </div>
              </div>
              <div
                class="row text-size-12 ps-3"
                v-if="
                  merchantMember.notice_transaction_amount_expiry.length > 0
                "
              >
                <div
                  v-for="(
                    text, index
                  ) in merchantMember.notice_transaction_amount_expiry"
                  :key="index"
                >
                  <em v-html="text"></em>
                </div>
              </div>
              <div
                style="color: #0000ee"
                class="d-flex align-items-center col-lg-12 col-12 ps-3 text-size-13 text-bold"
              >
                {{
                  merchantMember.current_tier_name
                    ? merchantMember.current_tier_name
                    : "No tier"
                }}
              </div>
              <div class="col-lg-12 col-12 d-flex align-items-center">
                <div class="col-lg-11 col-10 mx-auto">
                  <material-progress
                    :percentage="merchantMember.level_percentage"
                    variant="fill"
                    color="info"
                    class=""
                    style="height: 6px"
                  />
                </div>
                <div
                  class="col-lg-1 col-2 mx-auto ps-1"
                  v-if="merchantMember.tier_icon"
                >
                  <img
                    :src="merchantMember.tier_icon"
                    class="avatar avatar-md border-radius-lg"
                    alt="user1"
                  />
                </div>
              </div>
              <div class="col-lg-12 col-12 d-flex align-items-center">
                <span class="text-size-12">{{
                  merchantMember.level_note
                }}</span>
                <i
                  style="color: var(--color); font-size: 18px"
                  class="material-icons-round ms-1"
                  >report</i
                >
              </div>
            </div>
          </div>
        </div>
        <div class="introduce px-2 mt-3 text-center" v-if="!isLogined">
          <div v-if="allow_member_to_signup_from_moo == 1 || allow_member_to_signup_from_moo == '1'">
            <span @click="gotoSignIn()">Login</span> or
            <span @click="gotoSignUp()">Sign up</span> to enjoy greater benefits!
          </div>
          <div v-else>
            <span @click="gotoSignIn()">Login</span> to enjoy greater benefits!
          </div>
        </div>
        <div class="mt-3">
        <!-- <hr style="margin-top: 0px; margin-bottom: 0px" />
          <div class="root_row">
            <div class="root_row_child">
              <span
                style="color: var(--color)"
                class="material-icons-round opacity-10 cursor-pointer text-center active md-30"
                >house</span
              >
            </div>
            <div class="root_row_child_text">Branch</div>
            <div class="root_row_child_icon">
              <span
                class="material-icons-round opacity-10 cursor-pointer text-center active"
                >navigate_next</span
              >
            </div>
          </div>
          <hr style="margin-top: 0px; margin-bottom: 0px" /> -->
          <!-- <div class="root_row">
            <div class="root_row_child">
              <span
                style="color: var(--color)"
                class="material-icons-round opacity-10 cursor-pointer text-center active md-30"
                >support</span
              >
            </div>
            <div class="root_row_child_text">Support Center</div>
            <div class="root_row_child_icon">
              <span
                class="material-icons-round opacity-10 cursor-pointer text-center active"
                >navigate_next</span
              >
            </div>
          </div>
          <hr style="margin-top: 0px; margin-bottom: 0px" /> -->
          <!-- <div class="root_row">
            <div class="root_row_child">
              <span
                style="color: var(--color)"
                class="material-icons-round opacity-10 cursor-pointer text-center active md-30"
                >hvac</span
              >
            </div>
            <div class="root_row_child_text">Terms & Conditions</div>
            <div class="root_row_child_icon">
              <span
                class="material-icons-round opacity-10 cursor-pointer text-center active"
                >navigate_next</span
              >
            </div>
          </div>
          <hr style="margin-top: 0px; margin-bottom: 0px" /> -->
          <!-- <div class="root_row">
            <div class="root_row_child">
              <span
                style="color: var(--color)"
                class="material-icons-round opacity-10 cursor-pointer text-center active md-30"
                >settings_applications</span
              >
            </div>
            <div class="root_row_child_text">Setting</div>
            <div class="root_row_child_icon">
              <span
                class="material-icons-round opacity-10 cursor-pointer text-center active"
                >navigate_next</span
              >
            </div>
          </div> -->
          <!-- <hr style="margin-top: 0px; margin-bottom: 0px" /> -->
          <div v-if="isLogined" class="root_row" @click="handleRedirectCompletedOrdersForPayFirst()">
            <div class="root_row_child">
              <span
                style="color: var(--color)"
                class="material-icons-round opacity-10 cursor-pointer text-center active md-30"
                >check_circle</span
              >
            </div>
            <div class="root_row_child_text">Completed orders</div>
            <div class="root_row_child_icon">
              <span
                class="material-icons-round opacity-10 cursor-pointer text-center active"
                >navigate_next</span
              >
            </div>
          </div>
          <div v-if="isLogined" class="root_row" @click="handleLogout()">
            <div class="root_row_child">
              <span
                style="color: var(--color)"
                class="material-icons-round opacity-10 cursor-pointer text-center active md-30"
                >logout</span
              >
            </div>
            <div class="root_row_child_text">Logout</div>
            <div class="root_row_child_icon">
              <span
                class="material-icons-round opacity-10 cursor-pointer text-center active"
                >navigate_next</span
              >
            </div>
          </div>
          <hr v-if="isLogined" style="margin-top: 0px; margin-bottom: 0px" />
        </div>
      </div>
    </div>
    <footer v-if="isLoadDone">
      <Footer></Footer>
    </footer>
    <QrcodeMember :phoneNumber="memberPhone" />
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Footer from "@/common/Footer/OrderFooter";
import MemberService from "../services/member.service";
import MaterialProgress from "@/components/MaterialProgress.vue";
import QrcodeMember from "@/components/QrcodeMember.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/js/dist/modal";
import { gotoMemberLogin, localStorageName } from '@/config/utils';

export default {
  name: "sign-in",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    Footer,
    MaterialProgress,
    QrcodeMember
  },
  data() {
    return {
      isLoadDone: false,
      snackbar: null,
      paymentMethod: "",
      branch: JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), '')),
      message: "",
      merchantCode: "",
      branchCode: "",
      tableCode: "",
      isLogined: false,
      currentMember: {},
      merchantMember: {},
      pointIcon: "",
      rewardPoint: 2,
      merchantLogo: localStorage.getItem(localStorageName('MERCHANT_LOGO'), ''),
      memberPhone: "",
      tableInfo: null,
      isOrderPayLater: false,
      pathType: "",
      allow_member_to_signup_from_moo: localStorage.getItem(localStorageName('ALLOW_MEMBER_TO_SIGNUP_FROM_MOO'), 1),
    };
  },
  watch: {
    progressPercentage() {
      this.updateProgressBarColor();
    },
  },
  created() {
    const merchantCode = this.$store.state.merchantCode;
    this.merchantCode = merchantCode ? `/${merchantCode}` : "";
    this.branchCode = this.$store.state.branchCode;
    this.pathType = this.$route.params.type;
    this.tableCode = this.$route.params.table_code;
    this.tableInfo = JSON.parse(localStorage.getItem('table_' + merchantCode.toLowerCase() + '_' + this.branchCode.toLowerCase() + '_' + this.tableCode.toLowerCase(), null));

    if (this.tableInfo) {
        if (this.tableInfo.stripe_connect) {
            this.isOrderPayLater = false;
        } else {
            this.isOrderPayLater = true;
        }
    } else {
        this.isOrderPayLater = false;
    }

    var memberInfoData = JSON.parse(localStorage.getItem(localStorageName('memberInfo'), ''));
    if (memberInfoData) {
      this.getDataMember();
      this.isLogined = true;
    } else {
      this.isLogined = false;
      this.isLoadDone = true;
    }
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    gotoSignIn() {
      // this.$router.push({ name: "SignIn", params: {} });
      gotoMemberLogin('profile');
      // window.open(process.env.VUE_APP_MEMBER_SITE_URL + this.merchantCode + "/sign-in?branch_code=" + this.branchCode + "&from=profile","_self");
    },
    gotoSignUp() {
      gotoMemberLogin('profile');
      // window.open(
      //   process.env.VUE_APP_MEMBER_SITE_URL +
      //     this.merchantCode +
      //     "/sign-in?branch_code=" +
      //     this.branchCode + "&from=profile",
      //   "_self"
      // );
    },
    handleLogout() {
      localStorage.removeItem(localStorageName('token'));
      localStorage.removeItem(localStorageName('memberInfo'));
      localStorage.removeItem(localStorageName('SELECTED_REWARD'));
      localStorage.removeItem(localStorageName('PAYMENT_METHOD'));
      // this.$router.push({ name: "SignIn", params: {} });
      // this.$router.push({ name: "OnlineOrdering", params: {} });
      var url = process.env.VUE_APP_MEMBER_SITE_URL + this.merchantCode + "/sign-in?isLogout=" + this.branchCode;

      if (this.tableCode) {
        url += '&table_code=' + this.tableCode;
      }

      window.open(url, "_self");
    },
    goBack() {
      this.$router.go(-1);
    },
    getDataMember() {
      this.isLoadDone = false;
      this.showLoader();
      let dataForm = {
        menuset_id: this.branch.menuset_id ?? 0,
        search_keyword: this.searchKeyword,
      };

      MemberService.getDataMember(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.currentMember = response.data.data.member;
            this.merchantMember = response.data.data.merchant_member;
            this.pointIcon = response.data.data.point_icon;
            this.memberPhone = this.currentMember.phone_number;
            this.isLoadDone = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },
    handleRedirectCompletedOrdersForPayFirst() {
      var url = location.protocol + '//' + location.host+"/"+this.$store.state.merchantCode;

      if (this.tableInfo) {
          url += "/QR/"+this.branchCode+"/"+this.tableCode+"/completed-order-history-for-pay-first";
      } else {
          url += "/"+this.branchCode+"/completed-order-history-for-pay-first";
      }

      window.location.href = url;
    }
  },
};
</script>
<style>
.root_child_name {
  display: inline-flex;
  gap: 10px;
}
.a_go_back {
  width: 10%;
  height: fit-content;
}
.text_go_back {
  color: black;
  height: fit-content;
}
.root_logout {
  width: 100%;
  position: absolute;
  bottom: 0px;
}
.root_child_logout {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}
.btn_logout {
  width: 100%;
  background: orangered !important;
  color: white !important;
}
.root_row {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  height: 50px;
}
.root_row_child {
  width: 10%;
  text-align: center;
  display: flex;
}
.root_row_child_text {
  width: 80%;
  color: black;
  font-size: 16px;
}
.root_row_child_icon {
  width: 10%;
  text-align: center;
  display: flex;
}
.hello-name {
  color: black;
  height: fit-content;
  font-size: 25px;
  font-weight: 700;
}
.p-header {
  width: 100%;
  background: var(--color);
  padding: 15px;
}
input::placeholder {
  font-size: 15px;
  text-align: left;
  font-weight: 600;
  padding-left: 10px;
}
.visa {
  width: 50%;
  text-align: left;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  height: 80px;
}
.visaActive {
  width: 50%;
  text-align: left;
  border: 5px solid var(--color);
  border-radius: 5px;
  height: 80px;
}
.master {
  width: 50%;
  text-align: left;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  height: 80px;
}
.masterActive {
  width: 50%;
  text-align: left;
  border: 5px solid var(--color);
  border-radius: 5px;
  height: 80px;
}
.googlePay {
  width: 50%;
  text-align: left;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  height: 80px;
}
.googlePayActive {
  width: 50%;
  text-align: left;
  border: 5px solid var(--color);
  border-radius: 5px;
  height: 80px;
}
.applePay {
  width: 50%;
  text-align: left;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  height: 80px;
}
.applePayActive {
  width: 50%;
  text-align: left;
  border: 5px solid var(--color);
  border-radius: 5px;
  height: 80px;
}
.text-bold-500 {
  color: black;
  font-weight: 500;
}
.text-bold-400 {
  color: black;
  font-weight: 400;
}
.line {
  width: 100%;
  height: 5px;
  background: #bdbdbd;
}
.text-end {
  color: black;
  width: 20%;
  text-align: end;
}
.progress-bar {
  height: 20px;
  width: 100%;
}
.container-custom {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-custom {
  flex: 1;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
}
footer {
  background-color: white;
  padding-top: 10px;
}
.introduce {
  color: black;
  font-size: 18px;
}
.introduce span {
  text-decoration: underline;
  color: #1a73e8 !important;
  cursor: pointer;
}
</style>
